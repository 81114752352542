<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据中心</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">每日签到数据统计</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox searMg">
            <div title="学员信息" class="searchboxItem ci-full">
              <span class="itemLabel">学员信息:</span>
              <el-select v-model="retrievalData.userId" placeholder="请选择" no-data-text="没有数据" remote size="small"
                clearable :remote-method="superUserSelect" @visible-change="clearUserSearchModel">
                <template>
                  <div class="select-header">
                    <span class="select-header_1">学员姓名</span>
                    <span class="select-header_1">身份证号码</span>
                    <span class="select-header_1">学员电话</span>
                  </div>
                  <div class="select-header">
                    <el-input v-model="studentInfo.seaUserName" @input="superUserSelect" type="text" size="small"
                      placeholder="学员姓名" clearable style="width: 100px" />
                    <el-input v-model="studentInfo.seaUserIdcard" @input="superUserSelect" type="text" size="small"
                      placeholder="身份证号码" clearable style="margin-left: 50px; width: 140px" />
                    <el-input v-model="studentInfo.seaUserMobile" @input="superUserSelect" type="text" size="small"
                      placeholder="学员电话" clearable style="margin-left: 50px; width: 140px" />
                  </div>
                </template>
                <el-option v-for="(item, index) in studentInfo.userList" :key="index" :label="item.userName"
                  :value="item.userId">
                  <span style="width: 100px; font-size: 13px">{{
                    item.userName
                  }}</span>
                  <span style="width: 140px; margin-left: 50px; font-size: 13px">{{ item.idcard }}</span>
                  <span style="width: 140px; margin-left: 50px; font-size: 13px">{{ item.mobile }}</span>
                </el-option>
              </el-select>
            </div>
            <div title="班级信息" class="searchboxItem ci-full">
              <span class="itemLabel">班级信息:</span>
              <el-select v-model="retrievalData.projectId" placeholder="请选择" remote size="small" clearable
                :remote-method="superProjectSelect" @visible-change="clearProjectSearchModel">
                <template>
                  <div class="select-header">
                    <span class="select-header_2">班级编号</span>
                    <span class="select-header_2">班级名称</span>
                  </div>
                  <div class="select-header">
                    <el-input v-model="classInfo.seaProjectCode" @input="superProjectSelect" type="text" size="small"
                      placeholder="班级编号" clearable style="width: 150px" />
                    <el-input v-model="classInfo.seaProjectName" @input="superProjectSelect" type="text" size="small"
                      placeholder="请输入班级名称" clearable style="margin-left: 50px; width: 250px" />
                  </div>
                </template>
                <el-option v-for="(item, index) in classInfo.projectList" :key="index" :label="item.projectName"
                  :value="item.projectId">
                  <span style="width: 150px; font-size: 13px">{{ item.projectCode }}</span>
                  <span style="width: 250px; margin-left: 50px; font-size: 13px">{{ item.projectName }}</span>
                </el-option>
              </el-select>
            </div>
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-select size="small" v-model="retrievalData.compId" remote :remote-method="getCompanyList" filterable
                clearable placeholder="请至少输入两个字搜索">
                <el-option v-for="item in CompanyList" :key="item.compId" :label="item.compName"
                  :value="item.compId"></el-option>
              </el-select>
            </div>
            <div title="签到时间" class="searchboxItem ci-full">
              <span class="itemLabel">签到时间:</span>
              <el-date-picker v-model="retrievalData.signInTime" type="daterange" size="small" range-separator="至"
                value-format="yyyy-MM-dd" value="yyyy-MM-dd" start-placeholder="开始日期"
                end-placeholder="结束日期"></el-date-picker>
            </div>
          </div>
          <div class="searchbox">
            <div title="签到类型" class="searchboxItem ci-full">
              <span class="itemLabel">签到类型:</span>
              <el-select size="small" v-model="retrievalData.loginType" clearable placeholder="请选择签到类型">
                <el-option v-for="item in loginTypeData" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="签到方式" class="searchboxItem ci-full">
              <span class="itemLabel">签到方式:</span>
              <el-select size="small" v-model="retrievalData.loginWay" clearable placeholder="请选择签到方式">
                <el-option v-for="item in loginWayData" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="签到状态" class="searchboxItem ci-full">
              <span class="itemLabel">签到状态:</span>
              <el-select size="small" v-model="retrievalData.loginState" clearable placeholder="请选择签到状态">
                <el-option v-for="item in loginStateData" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            <el-button type="primary" class="bgc-bv" round @click="exportData">导出</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
              <el-table-column label="学员姓名" align="left" show-overflow-tooltip prop="userName" minWidth="100" />
              <el-table-column label="身份证号" align="left" show-overflow-tooltip prop="idcard" minWidth="150" />
              <el-table-column label="手机号" align="left" show-overflow-tooltip prop="mobile" minWidth="100" />
              <el-table-column label="班级编码" align="left" show-overflow-tooltip prop="projectCode" min-width="200" />
              <el-table-column label="班级名称" align="left" show-overflow-tooltip prop="projectName" min-width="200" />
              <el-table-column label="机构名称" align="left" show-overflow-tooltip prop="compName" min-width="200" />
              <el-table-column label="签到类型" align="left" show-overflow-tooltip prop="loginType" min-width="100">
                <template slot-scope="scope">
                  <span v-if="scope.row.loginType == '10'">每日签到</span>
                  <span v-else-if="scope.row.loginType == '20'">课中签到</span>
                  <span v-else-if="scope.row.loginType == '30'">课前签到</span>
                  <span v-else>全部</span>
                </template>
              </el-table-column>
              <el-table-column label="签到方式" align="left" show-overflow-tooltip prop="loginWay" min-width="100">
                <template slot-scope="scope">
                  <span v-if="scope.row.loginWay == '10'">人脸</span>
                  <span v-else-if="scope.row.loginWay == '20'">弹窗</span>
                  <span v-else>全部</span>
                </template>
              </el-table-column>
              <el-table-column label="签到状态" align="left" show-overflow-tooltip prop="faceState" min-width="100">
                <template slot-scope="scope">
                  <span v-if="scope.row.faceState == '1'">成功</span>
                  <span v-else-if="scope.row.faceState == '0'">失败</span>
                  <span v-else>全部</span>
                </template>
              </el-table-column>
              <el-table-column label="签到时间" align="left" show-overflow-tooltip prop="createTime" minWidth="150">
                <template slot-scope="scope">
                  <span class="ts_color_1">{{ scope.row.createTime | moment }}</span>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "classListCom_classEndReminder",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  doNotInit: true,
  data() {
    return {
      // 检索数据
      retrievalData: {
        userId: "", // 学员id
        projectId: "", // 班级id
        compId: "", // 机构id
        signInTime: '', // 签到时间
        loginType: "", // 签到类型
        loginWay: "", // 签到方式
        loginState: "", // 签到状态
      },
      // 学员信息
      studentInfo: {
        seaUserName: "", // 姓名
        seaUserIdcard: "", // 身份证号
        seaUserMobile: "", // 手机号
        userList: [], // 数据
      },
      // 班级信息
      classInfo: {
        seaProjectName: "", // 班级名称
        seaProjectCode: "", // 班级di
        projectList: [], // 数据
      },
      // 机构数据
      CompanyList: [],
      // 签到类型数据
      loginTypeData: [{
        value: "",
        label: "全部"
      }, {
        value: "10",
        label: "每日签到"
      }, {
        value: "20",
        label: "课中签到"
      }, {
        value: "30",
        label: "课前签到"
      }],
      // 签到方式数据
      loginWayData: [{
        value: "",
        label: "全部"
      }, {
        value: "10",
        label: "人脸"
      }, {
        value: "20",
        label: "弹窗"
      }],
      // 签到状态数据
      loginStateData: [{
        value: "",
        label: "全部"
      }, {
        value: "1",
        label: "成功"
      }, {
        value: "0",
        label: "失败"
      }]
    };
  },
  created() {
    this.dataDictionary();
    this.superUserSelect();
    this.superProjectSelect();
  },
  watch: {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  mounted() {},
  methods: {
    // 获取 - 数据字典
    dataDictionary() {
      const classStatusList = this.$setDictionary("PROJECTSTATE", "list");
      const list = [];
      list.push({
        value: "",
        label: "全部",
      });
      for (const key in classStatusList) {
        if (key == "" || key == "50" || key == "60") {
          list.push({
            value: key,
            label: classStatusList[key],
          });
        }
      }
      this.classStatusList = list;
    },
    // 获取 - 机构数据
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 获取 - 学员信息
    superUserSelect(e) {
      this.$post(
        "/biz/user/superUserSelect",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          userName: this.studentInfo.seaUserName,
          idcard: this.studentInfo.seaUserIdcard,
          mobile: this.studentInfo.seaUserMobile,
          projectId: this.studentInfo.projectId,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.studentInfo.userList = [{}];
        } else {
          this.studentInfo.userList = res.data.list;
        }
      });
    },
    // 获取 - 学员信息 - 下拉框出现/隐藏时触发
    clearUserSearchModel(e) {
      this.studentInfo = this.$options.data().studentInfo;
      this.superUserSelect();
    },
    // 获取 - 班级信息
    superProjectSelect(e) {
      this.$post(
        "/biz/project/superSelectProject",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          projectName: this.classInfo.seaProjectName,
          projectCode: this.classInfo.seaProjectCode,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.classInfo.projectList = [{}];
        } else {
          this.classInfo.projectList = res.data.list;
        }
      });
    },
    // 获取 - 班级信息 - 下拉框出现/隐藏时触发
    clearProjectSearchModel(e) {
      if (e) {
        this.classInfo = this.$options.data().classInfo;
        this.superProjectSelect();
      }
    },
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.retrievalData.userId) {
        params.userId = this.retrievalData.userId;
      }
      if (this.retrievalData.projectId) {
        params.projectId = this.retrievalData.projectId;
      }
      if (this.retrievalData.compId) {
        params.compId = this.retrievalData.compId;
      }
      if (this.retrievalData.signInTime) {
        params.startTime = this.retrievalData.signInTime[0];
        params.endTime = this.retrievalData.signInTime[1];
      }
      if (this.retrievalData.loginType) {
        params.loginType = this.retrievalData.loginType;
      }
      if (this.retrievalData.loginWay) {
        params.loginWay = this.retrievalData.loginWay;
      }
      if (this.retrievalData.loginState) {
        params.loginState = this.retrievalData.loginState;
      }
      this.doFetch({
        url: "/biz/userface/operate/everyDay/login/pageList",
        params,
        pageNum,
      });
    },
    // 导出 - 列表数据
    exportData() {
      const params = {};
      if (this.retrievalData.userId) {
        params.userId = this.retrievalData.userId;
      }
      if (this.retrievalData.projectId) {
        params.projectId = this.retrievalData.projectId;
      }
      if (this.retrievalData.compId) {
        params.compId = this.retrievalData.compId;
      }
      if (this.retrievalData.signInTime) {
        params.startTime = this.retrievalData.signInTime[0];
        params.endTime = this.retrievalData.signInTime[1];
      }
      if (this.retrievalData.loginType) {
        params.loginType = this.retrievalData.loginType;
      }
      if (this.retrievalData.loginWay) {
        params.loginWay = this.retrievalData.loginWay;
      }
      if (this.retrievalData.loginState) {
        params.loginState = this.retrievalData.loginState;
      }
      this.$post("/biz/userface/operate/everyDay/login/export", params)
        .then((res) => {
          if (res.status == "0") {
            let list = [];
            list.push(res.data);
            for (let item of list) {
              if (!this.downloadItems.includes(item.taskId)) {
                this.$store.dispatch("pushDownloadItems", item.taskId);
              } else {
                this.$message.warning(
                  "[" + item.fileName + "]已经申请下载,请耐心等待"
                );
              }
            }
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          return;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;

  .select-header_1 {
    color: #8492a6;
    float: left;
    font-size: 13px;
    margin-left: 50px;
    width: 140px;
    &:first-child {
      width: 100px;
      margin-left: 0;
    }
  }

  .select-header_2 {
    color: #8492a6;
    float: left;
    font-size: 13px;
    &:first-child {
      width: 150px;
    }
    &:last-child {
      width: 250px;
      margin-left: 50px;
    }
  }
}
</style>